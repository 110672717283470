* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
}

.navbar {
  background-color: #161616;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1500px;
}

.nav-logo {
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  margin-bottom: 0;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffaf02;
}

.nav-item .active {
  color: #ffaf02;
}

.nav-icon {
  display: none;
}

.nav-item .drop-menu{
  position: absolute;
  background: #242526;
  width: 225px;
  line-height: 38px;
  top: 80px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  font-size: 14px;
}
.nav-item .drop-menu li {
  list-style: none;
}
.nav-links:hover .drop-menu {
  transition: all 0.3s ease;
  top: 57px;
  opacity: 1;
  visibility: visible;  
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0;
  font-weight: 400;
  border-radius: 0px;
  color: #fff;
  text-align: left;
  padding-left: 15px;
}
.drop-menu li a:hover {
  background-color: #ffaf02;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 71px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242526;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffaf02;
    border: none;
  }
  .nav-links {
    padding: .2rem;
    width: 100%;
    display: table;
    position: relative;
  }
  .drop-menu li a {
    text-align: center;
  }
  .nav-item .drop-menu {
    display: none;
    transition: all 0.3s ease;
  }
  .nav-links:hover .drop-menu {
    display: block;
    position:relative;
    top: 10px;
    width: 100%;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
    color: #ffaf02;
  }
  .nav-logo {
    margin-left:0px;
  }
}