/* Scss Document */
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  letter-spacing:.5px;
}
p {
  letter-spacing:.3px;
  font-size:16px;
  
}
hr {
	margin-top: 0rem;
	margin-bottom: 0rem;	
}
img {
  max-width: 100%;
  border: 0;
}

.shake { animation: shake 1s cubic-bezier(.3,.06,.2,.9) infinite;}
.m-t-25 { margin-top:25px;}
.clear {
  clear: both;
  float: none;
  width: 100%;
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
a {
  text-decoration: none !important;
  color:#051643;
}
a:hover{
  color:#848484;
}
.py-md-60 {
	padding:60px 0px;
}
.p-t80-b60 {
    padding-top: 60px;
    padding-bottom: 10px;
}
.bg-gradient {
    background: #13407d;
}
.m-b-30 {
    margin-bottom: 30px;
}
/*---------main--css-----------------*/

.header-right {
	display:flex;
}
.header-right-bar {
	color:#fff; display:flex; margin-left: 20px; font-size:18px;
}
.header-right-bar i {  padding-right:5px; font-size:28px;}


.banner { background:url("../src/images/banner-bg.avif"); }
/* .banner:before { 
	content:"";
	background:rgba(0, 0, 0, 1);
	position:absolute;
	left:0px;
	top:0px;
	bottom:0px; right:0px;} */
.banner .banner-text { text-align:center; color:#fff;  height:80vh; display: flex;  
    -webkit-box-align: center; -ms-flex-align: center; align-items: center; position: relative; width:100%; }

.banner-inner { display:block; margin:auto; }

.banner_form .form-control {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 4px solid #ccc;
    border-radius: 10px;
    height: 44px;
    font-size: 14px;
}
.banner_form .border-btn { width:100%;  }
.banner .banner-text h1 {  font-size: 40px; font-weight: bold;}	
.banner-inner img { padding:10px; background:#fff; border-radius:6px;}

.banner_form { position:absolute; width:100%; bottom: 30px; background: linear-gradient(270deg,#051643,#1f43a1,#1f43a1,#051643);
    padding: 20px;  z-index:9; text-align:center;     
    border-radius: 5px; }
.banner_form .banner_form_section {
    width: 80%; margin:auto;}
.banner_form { display:none;}
.banner_form .banner_form_section h2{
	font-weight:bold;
}
.btn-style02 {
    text-align: center;
    font-size: 14px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 20px 20px 21px;
    border: 2px solid #b20407;
    border-radius: 5px;
    line-height: 1.1em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    white-space: nowrap;
    max-width: 200px;
    display: inline-block;
    cursor: pointer;
}
.btn-style02:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #b20407;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-transition: all .34s ease-out;
    -o-transition: all .34s ease-out;
    transition: all .34s ease-out;
	
}

.btn-style02:hover{color:#fff;}
.btn-style02:hover:before{width:0;left:auto;right:0}

.btn-style {
    text-align: center;
    font-size: 14px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 20px 35px 21px;
    border: 2px solid #051643;
    border-radius: 5px;
    line-height: 1.1em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    white-space: nowrap;
}
.btn-style:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #051643;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-transition: all .34s ease-out;
    -o-transition: all .34s ease-out;
    transition: all .34s ease-out;
	
}

.btn-style:hover{color:#000;}
.btn-style:hover:before{width:0;left:auto;right:0}

.border-btn {
    padding: 12px 40px;
    font-size: 15px;
    color: #fff!important;
    background: -webkit-linear-gradient(to right,#2044a2,#fbbf5a);
    background: linear-gradient(270deg,#071948,#2044a2,#2044a2,#071948);
    z-index: 1; border-radius: 30px; transition: all 5s;
	
}
.border-btn:hover { background: linear-gradient(to right,#051643,#2044a2); }

a.btn-white {
    color: #000;
    background-color: #fff;     padding: 8px 30px;
    border-radius: 10px;
}
a.btn-white:hover {
    color: #fff;
    background-color: #b20407;
	-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
     box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

/*--elep--*/
.elep {
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.blogview {
    background-color: #fbfbfb;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.blogview h5 {
    color: #1f43a1;
    font-weight: 600;
    font-size: 18px;
}
/*--services--section--*/
.service-content .shadow-box {
    margin-right: 15px;
    padding: 20px 0;
    text-align: left;
    border-radius: 10px;
    position: relative;
    border: 1px solid #eee;
    transition: all .4s ease-out 0s;
	display:flex;
	
}
.service-content .shadow-box.android.rev {
    border-color: #a4c639;
}
.service-content .shadow-box.rev {
    border-color: #000;
    box-shadow: none;
}
.service-content .shadow-box.windows.rev {
    border-color: #1094f1;
}
.service-content .shadow-box.blockchain.rev {
    border-color: #a241bb;
}
.service-content .shadow-box.ar.rev {
    border-color: #1d13ed;
}
.service-content .shadow-box.vr.rev {
    border-color: #0caec1;
}

.service-content .shadow-box.wearable.rev {
    border-color: #dc2d3c;
}
.service-content .shadow-box.game.rev {
    border-color: #cb1ac5;
}
.service-content .shadow-box.rev .service-icon {
    box-shadow: 0 0 14px -5px rgba(0,0,0,.2);
    height: 70px;
    width: 70px;
    background-color: #fff;
    display: inline-block;
    padding: 15px 0;
    text-align: center;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: -30px;
    position: relative;
}


.service-content:hover .shadow-box.rev {
    border-color: #bfbfbf!important;
    box-shadow: 0 0 20px -1px rgba(0,0,0,.30);
}
.service-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    color: #000;
	line-height:30px;
}
.section-heading .section-name { position:relative; margin-bottom: 60px; font-weight: bold; }
.section-heading .section-name p { font-size:17px; }
.section-name::before {
    position: absolute;
	left: 50%;
	bottom: -42px;
	content: "";
	height: 33px;
	width: 137px;
	transform: translate(-50%);
	background: url(../src/images/decor-title.png);
    
}


.process-section .section-heading .section-name {    
    margin-bottom: 50px;
}

/*---key-features--*/
.key-block.shadow-box {
    padding: 15px;
    margin-bottom: 35px;
    border-top: 3px solid #a241bb;
    transition: all .3s ease-out 0s;
	display: flex;
	box-shadow: 0 0 20px -5px rgba(0,0,0,.15);
    border-radius: 10px;
}
.key-block.shadow-box p { font-size:14px;}

.green-light-border {
    border-color: #a4c639!important;
}
.blue-border {
    border-color: #1094f1!important;
}
.light-blue-border {
    border-color: #1d13ed!important;
}
.purple-border {
    border-color: #a241bb!important;
}
.yellow-border {
    border-color: #ff9800!important;
}
.red-border {
    border-color: #dc2d3c!important;
}
.pink-border {
    border-color: #cb1ac5!important;
}
.green-blue-border {
    border-color: #0caec1!important;
}
.key-block.shadow-box h6 {
	font-size:18px; font-weight: 600;
}
.key-icon {
    margin-right: 15px;
}

.key-block.shadow-box:hover {
    box-shadow: 0 20px 23px -5px rgba(0,0,0,.2);
}

/*--app-development--*/	
.app-development {
	/* background: url(../src/images/tabs_block.png); */
	padding:40px 0px 40px 0px;
	}
.app-development-title { 
	font-weight: 800;
	font-size: 26px;
	line-height: 48px;
	position:relative;
	max-width: 625px;
	margin:auto;
	letter-spacing: .5px;
	padding-top:60px;
	position:relative;
}	

.app-development-title:before { 
	position: absolute;	
	top: 15px;
	left:50%;
	content: "";
	height: 33px;
	transform: translate(-50%);
	width: 137px;
	background: url(../src/images/decor-title.png);}

.app-development-sub-title {
    width: 70%;
	margin: auto;
    color: rgb(67, 76, 99);
    text-align: center;
    max-width: 725px;
    font-size: 20px;
    line-height: 36px;
}
.app-development p { color: #282d3b;
    font-size: 15px;
    line-height: 26px;}
.app-development img { max-width:75%; }
.app-development kbd { 
	margin:0px 6px; 
	font-size:14px;
	text-transform: uppercase;
    margin-left: 7px;
    margin-right: 7px;
    padding: 8px 15px;
    background-color: #1d202a;
    color: #fff;}
	
/*--your-business--*/
.your-business {
	padding:80px 0px 70px 0px;
	background:url(../src/images/mobile-app-dev.jpg);
	position:relative;
	background-size: cover !important;
	}
.your-business:before {
	content:"";
	background:rgba(0, 0, 0, .6);
	position:absolute;
	left:0px;
	top:0px;
	bottom:0px; right:0px;
}	
.your-business .topic {
    color: #fff;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
	padding-top: 25px;
	z-index:9;
	position:relative;
}
.your-business .sub-topic { color:#fff;}
.your-business .topic:before {
    position: absolute;	
	top: -15px;
	content: "";
	height: 33px;
	width: 137px;
	background: url(../src/images/decor-title.png);}

.your-business .sub-topic { 
	margin:10px auto 0;
	text-align:left;
	font-weight:300;
	max-width:714px;
	color:#fff;
	font-size:15px;
	line-height:25px;
	z-index:9;
	position:relative;
	}
.your-business p {    
    font-size: 15px;
	line-height: 26px;
    padding-top: 10px;
	margin-bottom: 29px;
}	

.your-business .tab-container { background:#fff; padding:45px 40px;  } 	
.your-business .tab-container .nav-tabs .nav-link.active {  border-bottom:4px solid #367dbf; color:#367dbf;} 	
.your-business .tab-container ul li { line-height: 26px; font-size: 16px;}
.mission {
    background: url(../src/images/mission-bg.jpeg);
}
.mission-section { 
	background:rgba(2, 2, 2, .3);
	padding:20px; border-radius:20px;
}
.mission-section p {
    color: #fff;
    font-size: 15px;
	margin-bottom:0px;
}
.mission-section .title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

/*--core-menu--*/
.service-tab a {
    padding: 2em 1.4em;
    text-align: center;
    display: block;
    background-color: #fcfcfc;
    border-radius: 10px;
    margin: 20px 0;
    border-bottom: 5px solid #e7e7e7;
}
.service-tab a h5 { font-size: 16px; font-weight: 600;}
.service-tab img{
    width: 100px;
    margin-bottom: 22px;
}
/*--app-cost--*/
.app-cost { padding-top:80px; padding-bottom:80px; }
.app-cost .inner { padding-left:80px; padding-right:20px;}
.app-cost .inner:before {
    position: absolute;	
	top: -45px;
	content: "";
	height: 33px;
	width: 137px;
	background: url(../src/images/decor-title.png);}

.app-cost h4 {
    color: #1d202a;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
}
.app-cost p{ margin-top: 15px;
    color: #383d4c;
    font-size: 15px;
    line-height: 26px;	
 }
 .app-cost .btn { width:170px; }
 
 
 /*--about-us--*/
.about-us-banner { 
	position:relative;
	background:url(../src/images/about-banner.jpg);
	background-size:cover;
	}
.about-us-banner::before {
    content: "";
    background-image: url(../src/images/d-023.png);
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
	opacity:.8;
}

.about-us-inner {
    max-width: 800px;
    color: #fff;
    margin: auto; padding-top:80px;
}
.about-us-inner h2 {
	font-size:54px;
	}
.about-us-inner h4 {
    font-size: 39px;
    font-weight: 200;
    margin-top: 20px;
}	

.email-services-inner h4 b { color:#53a3f3; }

/*--android-app--banner*/
.android-app-banner img { width:350px; margin-top:30px;  }

.android-app {
	position:relative;
	background-size:cover;
	min-height:400px;
}
.android-app {
    max-width: 90%;
    color: #fff;
    margin: auto; padding-top:80px;
}
.android-app h2 {
	font-size:54px; font-weight: 700;
	}
	
.php-web-banner { margin-top:80px;}	
.php-web-banner img {  margin-top:30px; }
.php-web-app {
	position:relative;
	background-size:cover;
	min-height:400px;
}
.php-web-app {
    max-width: 90%;
    color: #fff;
    margin: auto; padding-top:80px;
}
.php-web-app h2 {
	font-size:54px; font-weight: 700;
	}
	
	
.web-development {
	position:relative;
	/* background:url(../src/images/web-development.jpg);	 */
	background-size:cover;
	min-height:400px;
}
.web-design {
	position:relative;
	/* background:url(../src/images/tues-day-blog-4.jpg);	 */
	background-size:cover;
	min-height:400px;
}
.web-design::before {
    content: "";
    background: #000;   
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
	opacity:.4;
}

/*inner-pages-banner--*/
.inner-pages-banner {
	position:relative;
	background:url(../src/images/email-1.jpg);	
	background-size:cover;
	min-height:400px;
}
.inner-pages-banner::before {
    content: "";
    background: #000;   
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
	opacity:.4;
}

/*--contact-us-banner--*/
.contact-us-banner {
	position:relative;
	background:url(../src/images/contactus-banner.jpg);	
	background-size:cover;
	min-height:400px;
}
.contact-us-banner::before {
    content: "";
    background: #000;   
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
	opacity:.6;
}
	
/*--email-services--*/
.email-services-banner { 
	position:relative;
	background:url(../src/images/email-1.jpg);	
	}
.email-services-banner::before {
    content: "";
    background-image: url(../src/images/d-023.png);
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
	opacity:.8;
}

.email-services-inner {
    max-width: 800px; 
    color: #fff;
    margin: auto; padding-top:160px;
}
.email-services-inner h2 {
	font-size:54px; font-weight: 700;
	}
.email-services-inner h4 {
    font-size: 39px;
    font-weight: 200;
    margin-top: 20px;
}	

.email-services-inner h4 b { color:#53a3f3; }	
.email-services-inner-icon-tab { display:flex; margin-top: 30px; }
.email-services-inner-icon-tab .icon-bg  { 
	padding:10px; 
	margin:0px 10px; 
	border-radius:10px; 
	background:#fff;
	display: grid;
	align-items: center;}


/*--email--*/
.email-development {
    /* background: url(../src/images/tabs_block.png); */
    padding: 50px 0px 50px 0px;
}
.email-development-title { 
	font-weight: 700;
    padding-top: 25px;
	font-size: 35px;
	line-height: 48px;
	position:relative;
	margin:auto;
	letter-spacing: .5px;
	padding-top:60px;
}	

.email-business {
	padding:80px 0px 70px 0px;
	background:url(../src/images/email-service-bg.png);
	position:relative;
	background-size: cover !important;
	}
.email-business:before {
	content:"";
	background:rgba(0, 0, 0, .6);
	position:absolute;
	left:0px;
	top:0px;
	bottom:0px; right:0px;
}	
.email-business .topic {
    color: #fff;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
	padding-top: 25px;
	z-index:9;
	position:relative;
}
.email-business .topic:before {
    position: absolute;
    left: 15px;
    top: 0px;
    content: "";
    height: 4px;
    width: 60px;
    background-color: #051643;
}
.email-business .sub-topic { 
	margin:10px auto 0;
	text-align:left;
	font-weight:300;
	max-width:714px;
	color:#fff;
	font-size:15px;
	line-height:25px;
	z-index:9;
	position:relative;
	}
.email-business p {    
    font-size: 15px;
	line-height: 26px;
    padding-top: 10px;
	margin-bottom: 29px;
}	

.email-business .tab-container { background:#fff; padding:55px 40px; height:280px;  } 	

.email-development-title:before { 
	position: absolute;
	left: 50%; 
	top:16px;
	content:""; 
	height: 33px; 
    width: 137px;
	transform: translate(-50%);
    background: url(../src/images/decor-title.png);}

.email-development-sub-title {
    margin: auto;
    color: rgb(67, 76, 99);
    text-align: center;
    font-size: 17px;
    line-height: 30px;
}
.email-development p { color: #282d3b;
    font-size: 15px;
    line-height: 26px;}
	

.email-series-details { 
	position: absolute;
    top: 10%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 30px 0 rgba(0,0,0,.1);
    height: 365px;
    width: calc(100% + 210px);
    padding-left: calc((47% - 100px)/ 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
	z-index:33;
}

/*--hosting-info--*/
.hosting-info-list .fa-check-circle:before {
    font-size: 28px;
    color: #1868dd;
}

/*-choose-postmen--*/
.choose-postmen {
	padding: 60px 0; margin-bottom: 60px;
	}
.choose-postmen-title { 
	font-weight: 800;
	font-size: 35px;
	line-height: 48px;
	position:relative;
	margin:auto;
	letter-spacing: .5px;
	padding-top:6px;
	text-transform:uppercase;
}	

.choose-postmen-title span { color:#051643; }
.choose-postmen-sub-title {
    width: 70%;
	margin: auto;
    color: rgb(67, 76, 99);
    text-align: center;
    max-width: 725px;
    font-size: 20px;
    line-height: 36px;
}
.choose-postmen p { color: #8c8c8c;
    font-size: 18px; margin-bottom: 0px;
    line-height: 26px;}
	
.why-choose-us-block h5 {
	font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
	color:#1e1e1e;
}	

.why-choose-us-block p { 
	color:#70799f;
    font-size: 19px;
    line-height: 28px;
    max-width: 290px;
    text-align: center;
    margin: auto;
}

.xs-single-pricing {
    background: #3c77ad;
    -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    padding: 26px 5px;
    color: #fff;
    font-size: 20px;
}

/*--service-items--*/
.service-items img:after {
    /* background: url(../src/images/about-shape.svg) no-repeat center; */
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    left: -17px;
    top: 15px;
    -webkit-animation: rotate 10s infinite linear alternate;
    animation: rotate 10s infinite linear alternate;
}
		
.service-items .topic {
    color: #282d3b;
    font-weight: 700;
    font-size: 35px;
    line-height: 48px;
	padding-top: 25px;
}
.service-items .topic:before {
	position: absolute;	
	top: -25px;
	content: "";
	height: 33px;
	width: 137px;
	background: url(../src/images/decor-title.png);
}
.service-items .sub-topic { 
	margin:10px auto 0;
	text-align:left;
	font-weight:300;
	max-width:714px;
	color:#999b9f;
	font-size:17px;
	line-height:26px;
	}
.service-items p {    
	color: #1d202a;
    font-size: 17px;
    line-height: 30px;
    padding-top: 10px;}	
	
.service-items .title {	
	font-size: 20px;
    font-weight: 800;
    line-height: 27px;
	color:#051643;
	letter-spacing: .4px;
}
.service-items ul li {
	margin-top: 10px;
    display: flex;
    color: #282d3b;
    font-size: 15px;
    line-height: 21px;
	letter-spacing: .4px;
}

.service-items ul li::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #1d202a;
    transform: rotate(45deg) translate(-10px,25px);
}

.hosting-info-list .info-icon {
    min-width: 46px;
    padding-right: 9px;
}
.hosting-info-list .la-check-circle:before {
    font-size: 28px;
    color: #1868dd;
}
	
/*---process-we-follow---*/
.step-num {
    font-size: 34px;
    font-weight: 700;
    opacity: .2;
}

.process-content .shadow-box {
    padding: 30px 15px 10px;
    margin-top: -22px;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #fff;
	height:250px;
	transition: all .3s ease-out 0s;
	box-shadow: 0 0 10px -5px rgba(0,0,0,.15);
}
.process-content:hover  .shadow-box  {
	box-shadow: 0 0 20px 4px rgba(0,0,0,.15); 
}
.process-content:hover .step-num { opacity: .4; }
.process-content .process-title {
    margin: 15px 0 15px; font-size: 18px; font-weight: 600;

}

/*--why-postmen--*/
.why-postmen { padding: 60px 0; }
.why-postmen .sub-title { color:#fff;     font-size: 1.17647rem; }
.why-postmen .title { color:#fff;     font-size: 2.11765rem; font-weight:bold; }
.why-postmen-title {
    font-weight: 800;
	color:#fff; font-size:28px;
}
.why-postmen p {
    color: #fff;
    font-size: 17px;
}

/*--partnership-models---*/
.bg { background:red; }
.partnership-models { padding:80px 0px;  background: linear-gradient(270deg,#051643,#1f43a1,#1f43a1,#051643); position:relative;}
.partnership-models::before {
    background: url(../src/images/pattern.svg);
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: .07;
}
.partnership-models	.partnership-title {
	color:#fff;
	}
.partnership-title p { font-size: 18px; line-height:30px; color:#fff;}	
.partnership-title h3 { font-size: 36px; font-weight: bold;}	
.partnership-title h2 { font-size: 60px; font-weight: 600;}	
.partnership-models .partnership-discription {
	background:#fff; padding:20px 20px 10px 20px; border-radius:10px;
	font-size: 16px; line-height:26px;
	}
.partnership-models .partnership-discription h5 {
	    border-bottom: 2px solid #19388a;
		padding-bottom:10px; font-weight: 600;
}	
.partnership-models .partnership-discription p { 
	padding-top:10px;
}

/*--data-counter--*/
.data-counter { background: url(../src/images/deta-count-bg.jpg) center center no-repeat #fff; background-size:cover;
}

.counter-value {
    font-size: 55px;
    font-weight: 600;
    line-height: normal;
}
.counter-name {
    font-size: 17px;
    display: block;
    font-weight: 600;
}

/*--blog--section--*/
.blog-content.shadow-box {
	    box-shadow: 0 20px 23px -5px rgba(0,0,0,.2);
    background-color: #fff;
	border-radius: 10px;
}

.post-img {
    width: 100%;
	border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 180px;
    min-height: 180px;
}
.post-time {
    min-width: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0b034e;
    background: -webkit-linear-gradient(to right,#1f43a1,#051643);
    background: linear-gradient(to right,#1f43a1,#051643);
    padding: 15px 10px 10px;
    font-weight: 600;
    border-bottom-left-radius: 10px;
	display: flex;
	color:#fff; text-align: center;
}
.post-time .big {
    font-size: 35px;
    line-height: 35px;
}
.post-time .small {
    font-size: 11px;
    display: block;
    margin-top: 5px;
}
.post-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 144px;
}
.post-title {
    padding: 8px 20px 0px 20px;
    z-index: 1;
	display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}
.post-title::before {
    background-color: #eee;
    width: 0%;
    height: 100%;
    top: 0;
	z-index:-1;
    border-bottom-right-radius: 10px;
	display: inline-block;
    content: "";
    position: absolute;
    left: 0;
	transition: all .3s ease-out 0s;
}
.blog-content:hover .post-title::before {
    width: 100%;
}
.post-title h3, .post-title h4 {
    line-height: 26px;
	    font-size: 17px;
		color:#0e195f;
}

/*--footer--*/
footer { padding:40px 0px; display:flex; background: #fbfbfb;}
.footer-header {
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 19px;
    margin-top: 0;
}
.footer-lists { padding-left:10px;}
.footer-lists li {
    line-height: 32px;
}
.footer-lists li a {
    font-size: 14px;
    color: rgba(0,0,0,.6);
    margin: 0;
    font-weight: 500;
    position: relative;
}
.footer-col { padding:0px 20px;}
.footer-form-col { width:45%; padding-left:20px;}

.contact-form .input-field {
    margin-bottom: 20px;
}

.contact-form textarea.form-control {
	height:70px ;
	}
.contact-form .form-control {
	border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 4px solid #ccc;
    border-radius: 10px;
    height: 44px; font-size:14px;
}

.contact-form .btn-white {
    color: #fff;
    background-color: #051643; padding: 8px 30px;
    border-color: #fff; border-radius: 30px;
}
.contact-form .btn-white:active, .btn-white:focus, .btn-white:hover {
    color: #051643;
    background-color: transparent;
    border-color: #051643;
	-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
     box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

/*--where--*/
.where  { background:#051643; }
.where .logo { background:#efefef; padding:25px; display: flex; }
.where address { padding:10px 0px 0px 20px;}
.where address h5 {
    margin: 3px 0 10px;
    letter-spacing: 1px;
    font-size: 20px;
    text-transform: initial;
    color: #fff;
}
.where address hr {
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: 0px;
    width: 130px;
    border: 1px dashed #fff;
}
/*--progress-bar--*/
.inner-process-steps {
    display: flex;
    flex-wrap: wrap;
}
.inner-process-steps li {
    flex: 1 1 0%;
    overflow: hidden; padding: 30px 0px 20px 0px;
}
.inner-process-steps .process-title {
    height: 40px; font-size:17px; margin-top: 20px;
}
li .point {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
    color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.55);
    opacity: .5;
    margin-top: 25px;
	position: relative;
}

li .point::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -7.85em;
    width: 17em;
    height: 1px;
    background: rgba(0,0,0,.75);
    z-index: 1;
}
.inner-process-steps li:hover {
	box-shadow: 0 0 20px -5px rgba(0,0,0,.15);
    border-radius: 10px;
    background-color: #fff;
}
.inner-process-steps li:hover .point {
    opacity: 1;
}
/*--social-icons--*/
.social-icon-list li { display:inline-block; }
.social-icon-list .social-icon, .social-icon-list .social-icon i, .social-icon-list .social-icon::before {
    transition: all .35s;
        transition-timing-function: ease;
    transition-timing-function: cubic-bezier(.31,-.105,.43,1.59);
}
.social-icon-list .social-icon {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 30px -5px rgba(56,80,152,.15);
    display: inline-block;
    height: 40px;
    line-height: 34px;
    margin-right: 5px;
    opacity: 1;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 40px;
}
.social-icon-list .social-icon::before {
    content: '';
    width: 120%;
    height: 120%;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 90%;
    left: -110%;
}
.social-icon-list .social-icon.faa-facbook::before {
 background-color:#3b5999
}
.social-icon-list .social-icon.faa-twitter::before {
 background-color:#55acee
}
.social-icon-list .social-icon.faa-google::before {
 background-color:#dd4b39
}
.social-icon-list .social-icon.faa-linkedin::before {
 background-color:#0077b5
}
.social-icon-list .social-icon.faa-pinterest::before {
 background-color:#dd4b39
}
.social-icon-list .social-icon i {
    font-size: 24px;
    vertical-align: middle;
    transform: scale(.8);
}
.social-icon.faa-facbook i {
    color: #3b5999;
}
.social-icon.faa-twitter i {
    color: #55acee;
}
.social-icon.faa-google i {
    color: #dd4b39;
}
.social-icon.faa-linkedin i {
    color: #0077b5;
}
.social-icon.faa-pinterest i {
    color: #dd4b39;
}
.social-icon-list .social-icon:focus::before,
.social-icon-list .social-icon:hover::before {
 top:-10%;
 left:-10%
}
.social-icon-list .social-icon:focus i,
.social-icon-list .social-icon:hover i {
 color:#fff
}
.social-icon-list .social-icon,
.social-icon-list .social-icon i,
.social-icon-list .social-icon::before {
 transition:all .35s;
 transition-timing-function:cubic-bezier(.31,-.105,.43,1.59);
}


/*--copyright--*/
.footer-bottom {
    background: #222222;
}
.footer-bottom .copyright ul {
    padding: 0px;
    margin-bottom: 0px;
    color: #fff;
}
.footer-bottom .copyright ul li {
    margin: 5px 0;
    display: inline-block;
    padding: 0px 6px;
    
}
.footer-bottom .copyright ul li a {
	color: #fff;
	}
.footer-bottom .copyright ul li a:hover {
	color: #b20407;
	}	
.footer-bottom .copyright p {
    font-size: 14px;
    margin-bottom: 0px;
    text-align: right;
    padding-top: 3px;
}
@media (max-width:1280px)  {  
    .row { overflow-y: hidden;}
}
@media (max-width:992px)  { 
    .php-web-banner { margin-top: 0px;}
    .php-web-app { padding-top: 20px;}
    .php-web-app h2 { font-size: 42px;}
	.inner-process-steps .process-title { font-size: 15px;}
	.inner-process-steps li { flex: 0 0 25%;}
    
}
@media (max-width:800px)  {
	.m-b-30 { margin-bottom:30px;}
	.banner .banner-text h1 { font-size: 45px;}
	.header-right-bar { position:absolute; right: 55px; top:18px; font-size: 15px;}
	.logo { position:relative; z-index:90;}
	.logo img { width:125px; }
	
}

@media (max-width:768px) { 
    .app-cost .inner { padding-left: 0px; padding-bottom:30px; }
	.footer-bottom .copyright ul, .footer-bottom .copyright p { text-align:center;}
	.banner_form { top:60px; padding-bottom:40px;}   
    .post-img { max-height: initial; min-height: initial;} 
    .service-tab .col-md-3 { width: 50%; }
    .choose-postmen-title { font-size: 30px; line-height: 40px;}
    .app-development-title {
        line-height: 36px;
    }
}
@media (max-width:580px)  {
    .why-postmen .title { font-size: 1.6rem;}
    .service-tab .col-md-3:nth-child(even) { padding-left: 5px; }
    .service-tab .col-md-3:nth-child(odd) { padding-right: 5px; }
    .service-tab a { margin: 10px 0; padding: 1em;}
    .service-tab img { width: 80px;  margin-bottom: 12px;}
    .btn-style02 { padding: 13px;}
    .banner-inner { max-width: 90%;}
    .post-content { min-height: auto;}
    .post-title h3, .post-title h4 { line-height:initial; font-size: 15px;}
    .p-t80-b60 { padding-top: 40px; }
    .data-counter .col-md-3 { width: 50%; }
    .counter-value { font-size: 36px;}
    .counter-name { font-size: 14px; }
    .service-content .shadow-box.rev .service-icon { margin-left: -12px; }
    .service-section .col-md-4 { width: 50%;  }
	.banner .banner-text h1 { font-size: 34px;}
	.logo img { width:100px; }
	.service-content .shadow-box { margin-right:0px;}
	.inner-process-steps li { flex: 0 0 50%;}
	.email-services-inner h2, .php-web-app h2 { font-size:44px; }
	.email-development-title, .service-items .topic, .section-heading .section-name, 
	.partnership-title h3 { font-size: 30px; line-height: 34px;}
	.android-app h2 { font-size:38px;}
	.your-business .topic { font-size:28px; margin-bottom:15px;}
	
}
@media (max-width:480px)  {  
    .service-content .shadow-box.rev .service-icon svg { width: 28px;}
    .service-content .shadow-box.rev .service-icon { height: 50px; width: 50px; padding: 5px 0; margin-right:7px}  
    .service-content .shadow-box { padding: 6px 0;}
    .service-title { font-size: 14.5px; line-height: 20px;}
.footer-form-col { width:96%; } 
.email-services-inner h2 { font-size:36px;}
}

@media (max-width:380px)  {
	footer { padding: 40px 5px;}
}